<template>
  <div class="search">
    <div class="search-header">
      <div id="allmapsearch"></div>

      <van-field
        clickable
        readonly
        :value="addressVal"
        label="省市区"
        placeholder="请选择"
        @click="addressShow = true"
        right-icon="arrow"
      ></van-field>
      <van-field
        v-show="countryValShow"
        clickable
        readonly
        :value="countryVal"
        label="乡镇"
        placeholder="请选择"
        @click="countryShow = true"
        right-icon="arrow"
      ></van-field>
      <van-field
        v-show="communityValShow"
        clickable
        readonly
        :value="communityVal"
        label="社区"
        placeholder="请选择"
        @click="communityShow = true"
        right-icon="arrow"
      ></van-field>
    </div>

    <div class="searchDetail" v-show="resultAddress">
      <div>查询结果：</div>
      <div v-html="resultAddress"></div>
      <div v-html="resultcountry"></div>
      <div v-html="resultcommunity"></div>
    </div>

    <van-popup v-model="addressShow" position="bottom">
      <van-area :area-list="areaList" @cancel="areaCancel" @confirm="areaConfirm"></van-area>
    </van-popup>

    <van-popup v-model="countryShow" position="bottom">
      <van-picker show-toolbar title="乡镇" :columns="countryList" @cancel="countryCancel" @confirm="countryConfirm" />
    </van-popup>

    <van-popup v-model="communityShow" position="bottom">
      <van-picker show-toolbar title="社区" :columns="communityList" @cancel="communityCancel" @confirm="communityConfirm" />
    </van-popup>

  </div>
</template>

<script>
import { Field, Search, Popup, Area, Picker, Toast } from "vant"
import areaList from "@/utils/area"
import { BaiDuMap } from '@/utils/map'
import { getAreaInfoProvDict, getAreaInfoCountyDict, getVillageInfoByAreaCode } from '@/utils/api'
import {uwStatisticAction} from "../../utils/util";

export default {
  name: 'search',
  components: {
    [Field.name]: Field,
    [Search.name]: Search,
    [Popup.name]: Popup,
    [Area.name]: Area,
    [Picker.name]: Picker,
    [Toast.name]: Toast
  },
  data() {
    return {
      ak: 'RdXGpvs4GG3OeBG1qKExl0YAtsmMbQ56',
      map: null,
      addressVal: '',
      local: null,
      addressShow: false,
      areaList,
      addressListAll: [],
      countryShow: false,
      countryList: [],
      countryListAll: [],
      countryVal: null,
      communityShow: false,
      communityList: [],
      communityListAll: [],
      communityVal: null,
      resultAddress: null,
      resultcountry: null,
      resultcommunity: null,
      countryValShow: false,
      communityValShow: false,
    }
  },
  methods: {
    // 获取某一县的镇村信息
    getAreaInfoCounty(countyNo) {
      this.countryList = []
      this.communityValShow = false
      getAreaInfoCountyDict(countyNo).then((res) => {
        if(res.code === 200 && res.data){
          this.countryListAll =  res.data.townList
          for(let obj of this.countryListAll){
            this.countryList.push(obj.name)
          }
          this.resultAddress = res.data.superLevelInfo + '<hr>'
          this.countryValShow = true
        }else{
          this.resultAddress = '无乡镇信息<hr>'
          this.countryValShow = false
        }
      }).catch(() => {
        this.countryValShow = false
      })
    },
    // 获取社区信息
    getVillageInfo(areaCode) {
      getVillageInfoByAreaCode(areaCode).then(res => {
        if(res.code == 200 && res.data){
          if(res.data.villageInfo){
            this.resultcommunity = res.data.villageInfo + '<hr>'
          }else{
            this.resultcommunity = '暂无信息' + '<hr>'
          }

        }
      }).catch(()=>{

      })
    },
    areaConfirm(val) {
      this.countryVal = ''
      this.countryList = []
      this.countryListAll = []
      this.communityVal = ''
      this.communityList = []
      this.communityListAll = []
      this.resultAddress = ''
      this.resultcountry = ''
      this.resultcommunity = ''
      let code = ''
      this.addressVal = ''
      this.addressShow = false
      for(let obj of val){
        this.addressVal += obj.name + ' '
        code += obj.name
      }
      this.local.search(code)
      this.getAreaInfoCounty(val[2].code + '000000')
      // // 省信息
      // for(let [i, obj] of this.addressListAll.entries()){
      //   if(val[0].code + '000000' == obj.areaCode){
      //     this.resultAddress = obj.lowerLevelInfo
      //     // 市信息
      //     for(let [n, m] of this.addressListAll[i].city.entries()){
      //       if(val[1].code + '000000' == m.areaCode){
      //         this.resultAddress += '<br>' + m.lowerLevelInfo
      //         // 区信息
      //         for(let k of this.addressListAll[i].city[n].county){
      //           if(val[2].code + '000000' == k.areaCode){
      //             this.resultAddress += '<br>' + k.lowerLevelInfo + '<hr>'
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
    },
    areaCancel() {
      this.addressShow = false
    },
    countryConfirm(val, index) {
      this.communityValShow = true
      this.communityVal = ''
      this.communityList = []
      this.countryShow = false
      this.countryVal = val
      this.resultcountry = this.countryListAll[index].lowerLevelInfo + '<hr>'
      this.communityListAll = this.countryListAll[index].county

      let tempData = this.communityListAll
      for(let [i, obj] of tempData.entries()){
        this.communityList.push(obj.name)
      }

      let lat = this.countryListAll[index].lat
      let lng = this.countryListAll[index].lng
      let point = new BMap.Point(lng, lat)

      let geocoder = new BMap.Geocoder()
      geocoder.getLocation(point, (rs) => {
        // console.log(rs)
        if(rs.addressComponents.streetNumber){
          this.local.search(rs.address)
        }else{
          this.map.clearOverlays();
          var marker = new BMap.Marker(point);  // 创建标注
          this.map.addOverlay(marker);              // 将标注添加到地图中
          this.map.panTo(point);
          this.map.centerAndZoom(point, 13)
        }
      })
    },
    countryCancel() {
      this.countryShow = false
    },
    communityConfirm(val, index) {
      this.communityShow = false
      this.communityVal = val
      this.getVillageInfo(this.communityListAll[index].areaCode)

      let lat = this.communityListAll[index].lat
      let lng = this.communityListAll[index].lng
      let point = new BMap.Point(lng, lat)

      let geocoder = new BMap.Geocoder()
      geocoder.getLocation(point, (rs) => {
        console.log(rs)
        if(rs.addressComponents.streetNumber){
          this.local.search(rs.address)
        }else{
          this.map.clearOverlays();
          var marker = new BMap.Marker(point);  // 创建标注
          this.map.addOverlay(marker);              // 将标注添加到地图中
          this.map.panTo(point);
          this.map.centerAndZoom(point, 13)
        }
      })

    },
    communityCancel() {
      this.communityShow = false
    },
    setMap() {
      // 在指定容器创建地图实例并设置最大最小缩放级别
      this.map = new BMap.Map('allmapsearch', {
        minZoom: 4,
        maxZoom: 18
      })

      // 初始化地图，设置中心点和显示级别
      this.map.centerAndZoom(new BMap.Point(120.38, 30.9), 7)

      // 开启鼠标滚轮缩放功能，仅对PC上有效
      this.map.enableScrollWheelZoom(true)

      // 将控件（平移缩放控件）添加到地图上
      this.map.addControl(new BMap.NavigationControl())

      // 创建位置检索、周边检索和范围检索
      this.local = new BMap.LocalSearch(this.map, {
        renderOptions: {
          map: this.map
        }
      })

      // let point = new BMap.Point('114.21443', '36.41955')
      // var markergg = new BMap.Marker(point);
      // this.map.addOverlay(markergg); //添加GPS marker
      // var labelgg = new BMap.Label("",{});
      // markergg.setLabel(labelgg);
      // this.map.centerAndZoom(point, 12)
      // this.map.setCenter(point);

      // var geocoder = new BMap.Geocoder()
      // map.addEventListener('click', function(e) {
      //   //给地图添加点击事件

      //   geocoder.getLocation(e.point, function(rs) {
      //     alert(rs.address) //地址描述(string)

      //     console.log(rs.addressComponents) //结构化的地址描述(object)
      //     console.log(rs.addressComponents.province) //省
      //     console.log(rs.addressComponents.city) //城市
      //     console.log(rs.addressComponents.district) //区县
      //     alert(rs.addressComponents.street) //街道
      //     alert(rs.addressComponents.streetNumber) //门牌号

      //     console.log(rs.surroundingPois) //附近的POI点(array)

      //     console.log(rs.business) //商圈字段，代表此点所属的商圈(string)
      //   })
      // })
    },
    // 获取省市县
    getProvData() {
      getAreaInfoProvDict().then((res) => {
        this.addressListAll = res.data
      }).catch(() => {

      })
    },

  },
  mounted() {
    this.$nextTick(() => {
      BaiDuMap(this.ak).then(BMap => {
        this.setMap()
      })
    })
    uwStatisticAction('/search','查询省市信息')
    // this.getProvData()


  }
}
</script>

<style lang="less" scoped>
.search {
  height: 100%;
  display: flex;
  flex-direction: column;
  #allmapsearch {
    overflow: hidden;
    height: 5rem;
  }
  .searchDetail {
    padding: 0 0.3rem 0.1rem;
  }
}
</style>
